import { RootPaths } from "@src/appV2/App/paths";

import { ACCOUNTS_PATH } from "../paths";

export const ACCOUNTS_DOCUMENTS_PATH = "documents";
export const ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH = "documents/:hcfId/:hcfName";
export const ACCOUNTS_DOCUMENT_DETAILS_PATH = "document-details";

export const ACCOUNTS_BASE_PATH = `${RootPaths.APP_V2_HOME}/${ACCOUNTS_PATH}`;
export const ACCOUNTS_DOCUMENTS_FULL_PATH = `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`;
export const ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH = `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENT_DETAILS_PATH}`;
