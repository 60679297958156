import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useCallback } from "react";

import { getQualificationForDocumentCheck as getQualificationForDocumentCheckHelper } from "../../helpers";

export function useGetQualificationForDocumentCheck() {
  const isNurseShiftDocumentCheckFixEnabled = useCbhFlag(
    CbhFeatureFlag.NURSE_SHIFT_DOCUMENT_CHECK_FIX,
    {
      defaultValue: false,
    }
  );

  const getQualificationForDocumentCheck = useCallback(
    (params: { shiftQualificationRequirement: string; selectedWorkerQualification: string }) => {
      const { shiftQualificationRequirement, selectedWorkerQualification } = params;
      if (isNurseShiftDocumentCheckFixEnabled) {
        return getQualificationForDocumentCheckHelper({
          shiftQualificationRequirement,
          selectedWorkerQualification,
        });
      }

      return shiftQualificationRequirement;
    },
    [isNurseShiftDocumentCheckFixEnabled]
  );

  return { getQualificationForDocumentCheck };
}
