import { type ApiResponse, post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const sendProofOfEarningsEmailRequestSchema = z.object({
  start: z.date(),
  end: z.date(),
});

const sendProofOfEarningsEmailResponseSchema = z.object({
  success: z.boolean(),
});

type SendProofOfEarningsEmailRequest = z.infer<typeof sendProofOfEarningsEmailRequestSchema>;
type SendProofOfEarningsEmailResponse = z.infer<typeof sendProofOfEarningsEmailResponseSchema>;

export function useSendProofOfEarningsEmail(
  options: UseMutationOptions<
    ApiResponse<SendProofOfEarningsEmailResponse>,
    AxiosError,
    SendProofOfEarningsEmailRequest
  > = {}
): UseMutationResult<
  ApiResponse<SendProofOfEarningsEmailResponse>,
  AxiosError,
  SendProofOfEarningsEmailRequest
> {
  return useMutation({
    mutationFn: async (data: SendProofOfEarningsEmailRequest) => {
      return await post({
        url: "/shift/payroll/mobile/sendEmail",
        data,
        requestSchema: sendProofOfEarningsEmailRequestSchema,
        responseSchema: sendProofOfEarningsEmailResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_SEND_PROOF_OF_EARNINGS_EMAIL_FAILURE,
    },
    ...options,
  });
}
