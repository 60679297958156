import { IonPage } from "@ionic/react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { ProofOfEarningsPage } from "./ProofOfEarnings/Page";
import { PROOF_OF_EARNINGS_PATH } from "./ProofOfEarnings/paths";

export function PayrollRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/${PROOF_OF_EARNINGS_PATH}`}>
        <IonPage>
          <ProofOfEarningsPage />
        </IonPage>
      </Route>
    </Switch>
  );
}
