import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

export const WORKER_PAYROLL_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/shift/payroll/mobile/v2`;

export const workerPayrollResponseSchema = z.object({
  shifts: z.array(
    z.object({
      isInstantPay: z.boolean(),
      isStripePayment: z.boolean(),
      paymentStatus: z.object({
        id: z.string(),
        amountPaid: z.number().optional(),
        totalAmount: z.number().optional(),
        status: z.string(),
      }),
      start: z.string(),
      end: z.string(),
      time: z.number(),
      pay: z.number(),
      verified: z.boolean(),
    })
  ),
  bonuses: z.array(z.object({})),
});
export type WorkerPayrollResponse = z.infer<typeof workerPayrollResponseSchema>;

export const workerPayrollParamsSchema = z
  .object({
    /** start date cannot be more than 31 days before end date, otherwise backend would throw "invalid input" */
    startDate: z.string(),
    endDate: z.string(),
  })
  .or(z.object({ relativeWeek: z.number() }));
export type WorkerPayrollParams = z.infer<typeof workerPayrollParamsSchema>;

export function getOpenCasesQueryKey(params: WorkerPayrollParams) {
  return [WORKER_PAYROLL_URL, params];
}

export function useWorkerPayroll(
  queryParams: WorkerPayrollParams,
  options: UseGetQueryOptions<WorkerPayrollResponse> = {}
) {
  return useGetQuery({
    url: WORKER_PAYROLL_URL,
    queryParams,
    requestSchema: workerPayrollParamsSchema,
    responseSchema: workerPayrollResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_PAYROLL_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 1,
    ...options,
  });
}
