import { z } from "zod";

export enum MappingLevel {
  GLOBAL = "GLOBAL",
  STATE = "STATE",
  COUNTY = "COUNTY",
  MSA = "MSA",
  HCF = "HCF",
}

export enum DocumentRequirementType {
  REJECTED = "rejected",
  EXPIRED = "expired",
  MISSING = "missing",
  REQUIRED_BY_FACILITIES = "missingRequirementAtHCFLevel",
  OPTIONAL = "optional",
  PENDING = "pending",
  COMPLETED = "completed",
  MISCELLANEOUS = "miscellaneous",
}

export enum DocumentStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum CheckrInvitationStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export enum CheckrPackageType {
  BASIC_CRIMINAL = "basic_criminal",
}

export enum CheckrReportStatus {
  PENDING = "pending",
  CLEAR = "clear",
  CONSIDER = "consider",
}

export const checkrInvitationStatusSchema = z.nativeEnum(CheckrInvitationStatus);

export const checkrReportStatusSchema = z.nativeEnum(CheckrReportStatus);

export const documentStatusSchema = z.nativeEnum(DocumentStatus);

export const MappingLevelSchema = z.nativeEnum(MappingLevel);

export const requirementChecklistApiResponse = z.object({
  enabled: z.boolean().optional(),
  description: z.string().nullish(),
  items: z.array(z.string()).nullish(),
});

export type RequirementChecklistApiResponse = z.infer<typeof requirementChecklistApiResponse>;

const requirementSchema = z.object({
  _id: z.string(),
  reqId: z.string().optional(),
  name: z.string(),
  description: z.string(),
  instructions: z.string(),
  disableWorkerUpload: z.boolean().nullish(),
  checklist: requirementChecklistApiResponse.nullish(),
  visibleToHCP: z.boolean(),
  url: z.string(),
  createdAt: z.date(),
  archived: z.boolean(),
  expiry: z.string().nullish(),
  level: MappingLevelSchema.optional(),
  requiredBy: z.string().optional(),
  validity: z.date().optional(),
  subType: z.string().optional(),
});

export type Requirement = z.infer<typeof requirementSchema>;

const requirementMappingSchema = z.object({
  _id: z.string(),
  requiredBy: z.array(z.string()),
  hcfType: z.array(z.string()),
  level: MappingLevelSchema,
  requiredForBooking: z.boolean().optional(),
});

const miscellaneousRequirementResponseSchema = z.object({
  _id: z.string(),
  name: z.string(),
  description: z.string(),
  instructions: z.string(),
  visibleToHCP: z.boolean().optional(),
  reqId: z.string().optional(),
  expiry: z.string().optional(),
});

export type MiscellaneousRequirementResponse = z.infer<
  typeof miscellaneousRequirementResponseSchema
>;

const hcpRequirementSchema = z.object({
  ...requirementMappingSchema.pick({
    _id: true,
    requiredBy: true,
    hcfType: true,
    level: true,
    requiredForBooking: true,
  }).shape,
  ...requirementSchema.pick({
    description: true,
    visibleToHCP: true,
    expiry: true,
    disableWorkerUpload: true,
    checklist: true,
  }).shape,
  name: z.string(),
  instructions: z.string(),
  reqId: z.string(),
});

export type HcpRequirement = z.infer<typeof hcpRequirementSchema>;

export const hcpRequirementStatusApiResponseSchema = z.object({
  completed: z.array(z.string()),
  expired: z.array(z.string()),
  expiredOptionalRequirements: z.array(z.string()),
  miscellaneous: miscellaneousRequirementResponseSchema,
  missing: z.array(z.string()),
  missingOptionalRequirements: z.array(z.string()),
  mostRecentCovidDoc: z
    .object({
      covidTest: z.string().nullable(),
    })
    .optional()
    .nullable(),
  optionalRequirements: z.array(hcpRequirementSchema),
  pending: z.array(z.string()),
  rejected: z.array(z.string()),
  requirements: z.array(hcpRequirementSchema),
  validOptionalRequirements: z.array(z.string()),
  optional: z.array(z.string()).optional(),
});

export const hcpRequirementStatusResponse = z.object({
  data: z.object({
    hcpRequirementStatus: hcpRequirementStatusApiResponseSchema,
  }),
});

export type HcpRequirementStatusApiResponse = z.infer<typeof hcpRequirementStatusApiResponseSchema>;

export type HcpRequirementStatusResponse = z.infer<typeof hcpRequirementStatusResponse>;

const HcpRequirementStatusSchema = z.object({
  ...hcpRequirementStatusApiResponseSchema.omit({
    requirements: true,
    miscellaneous: true,
    optionalRequirements: true,
  }).shape,
  pending: z.array(z.string()),
  missing: z.array(z.string()),
  optional: z.array(z.string()),
  expired: z.array(z.string()),
  completed: z.array(z.string()),
  rejected: z.array(z.string()),
  requirements: z.record(hcpRequirementSchema),
  missingRequirementAtHCFLevel: z.array(z.string()),
  miscellaneous: z.array(z.string()),
});

export type HcpRequirementStatus = z.infer<typeof HcpRequirementStatusSchema>;

const UseGetRequirementStatusSchema = z.object({
  hcpRequirementStatus: HcpRequirementStatusSchema,
  facilityIdsMissingRequirements: z.array(z.string()),
  totalCountOfMandatoryRequirements: z.number(),
  countOfPendingOrCompletedMandatoryRequirements: z.number(),
});

export type UseGetRequirementStatus = z.infer<typeof UseGetRequirementStatusSchema>;

const LocationStateSchema = z.object({
  returnUrl: z.string().optional(),
});

export type LocationState = z.infer<typeof LocationStateSchema>;

const checkrApplicationStatusSchema = z.object({
  invitationStatus: z.string().optional(),
  reportStatus: z.string().optional(),
});

export type CheckrApplicationStatus = z.infer<typeof checkrApplicationStatusSchema>;

export const checkrApplicationStatusResponseSchema = z.object({
  data: checkrApplicationStatusSchema,
});

export type CheckrApplicationStatusResponse = z.infer<typeof checkrApplicationStatusResponseSchema>;

export const onboardingCheckrInvitationResponseSchema = z.object({
  archived: z.boolean(),
  completed_at: z.string().nullish(),
  created_at: z.string(),
  id: z.string(),
});

export const onboardingCheckrInvitationRequestSchema = z.object({
  email: z.string(),
  packageType: z.nativeEnum(CheckrPackageType),
});

export type OnboardingCheckrInvitationRequest = z.infer<
  typeof onboardingCheckrInvitationRequestSchema
>;

export type OnboardingCheckrInvitationResponse = z.infer<
  typeof onboardingCheckrInvitationResponseSchema
>;

const notificationSchema = z.object({
  reason: z.string().optional(),
  pushNotification: z.boolean().optional(),
  note: z.string().optional(),
});

export const hcpDocumentSchema = z.object({
  _id: z.string(),
  archived: z.boolean().optional(),
  visible: z.boolean().optional(),
  issueDate: z.date().optional(),
  status: documentStatusSchema.optional(),
  covidTest: z.date().optional(),
  fileUrl: z.string().optional(),
  name: z.string().optional(),
  hcpId: z.string().optional(),
  expiry: z.date().optional(),
  rejectionReason: z.string().optional(),
  rejectionNotifiedAt: z.date().optional(),
  uploadedFor: requirementSchema.optional(),
  fulfilledRequirement: requirementSchema.optional(),
  notification: notificationSchema.optional(),
  createdAt: z.string(),
});

export type HcpDocument = z.infer<typeof hcpDocumentSchema>;

export const getFilteredDocumentsApiResponseSchema = z.object({
  data: z.object({
    documentList: z.array(hcpDocumentSchema),
  }),
});

export type GetFilteredDocumentsApiResponse = z.infer<typeof getFilteredDocumentsApiResponseSchema>;

export const getFilteredDocumentQueryParamsSchema = z.object({
  hcpId: z.string(),
  limit: z.number(),
  sort: z.object({
    createdAt: z.number(),
  }),
  filter: z.object({
    status: documentStatusSchema,
    fulfilledRequirementId: z.string(),
    workerUploaded: z.boolean(),
  }),
});

export type GetFilteredDocumentQueryParams = z.infer<typeof getFilteredDocumentQueryParamsSchema>;
export enum DocumentCompletionStatus {
  COMPLETED = "completed",
  NOT_COMPLETED = "not completed",
}

export enum DocumentUiVariant {
  V1 = "V1",
  V2 = "V2",
}
