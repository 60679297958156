import { RootPaths } from "@src/appV2/App/paths";

import { REFER_AND_EARN_SCREEN_NAME } from "./constants";

export const REFER_AND_EARN_PATH = "refer-and-earn";
export const REFER_AND_EARN_WITH_ACCOUNTS_PATH = `${RootPaths.HOME}/account/${REFER_AND_EARN_PATH}`;

export const ReferAndEarnRouterPath = {
  REFER_AND_EARN_PATH: REFER_AND_EARN_WITH_ACCOUNTS_PATH,
};

export const ReferAndEarnRouterPathInfo = {
  REFER_AND_EARN_PATH: {
    name: REFER_AND_EARN_SCREEN_NAME,
  },
};
