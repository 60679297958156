import { BaseLink, Text, Title } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/WorkplaceReferrals";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";

import { REFER_AND_EARN_SCREEN_NAME } from "./constants";
import { ReferAndEarnAction } from "./types";

export function ReferAndEarnPage() {
  const enabledWorkerToWorkerReferralBanners = useCbhFlag(
    CbhFeatureFlag.WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS,
    {
      defaultValue: { enterReferralCodeBannerIsVisible: false, referAndEarnBannerIsVisible: false },
    }
  );
  const { referAndEarnBannerIsVisible } = enabledWorkerToWorkerReferralBanners;

  useLogEffect(APP_V2_USER_EVENTS.REFER_AND_EARN, {
    action: ReferAndEarnAction.PAGE_VIEWED,
  });
  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={REFER_AND_EARN_SCREEN_NAME}
            leftCta={
              <BackButtonLink
                defaultBackTo={DeprecatedGlobalAppV1Paths.ACCOUNT}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.REFER_AND_EARN, {
                    action: ReferAndEarnAction.BACK_BUTTON_CLICKED,
                  });
                }}
              />
            }
          />
        }
      >
        <Stack direction="column" spacing={2}>
          <Card variant="outlined">
            <CardActionArea
              component={BaseLink}
              to={
                referAndEarnBannerIsVisible
                  ? DeprecatedGlobalAppV1Paths.REFERRAL_V2
                  : DeprecatedGlobalAppV1Paths.REFERRAL
              }
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.REFER_AND_EARN, {
                  action: ReferAndEarnAction.REFER_PROFESSIONALS_CLICKED,
                });
              }}
            >
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 0.5,
                  paddingRight: 1,
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Title bold component="h3">
                      Refer a Friend
                    </Title>
                    <Text>
                      Earn money when you refer a friend and they work their first shift! Tap to
                      share your referral code or to enter a code you received.
                    </Text>
                  </Stack>
                </CardContent>
                <ChevronRightIcon />
              </Stack>
            </CardActionArea>
          </Card>
          <Card variant="outlined">
            <CardActionArea
              component={BaseLink}
              to={{
                pathname: WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH,
                search: `?source=referAndEarnPage`,
              }}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.REFER_AND_EARN, {
                  action: ReferAndEarnAction.REFER_WORKPLACES_CLICKED,
                });
              }}
            >
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 0.5,
                  paddingRight: 1,
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Title bold component="h3">
                      Refer Facilities
                    </Title>
                    <Text>
                      Earn $1,000 every time you refer a facility (e.g. Long-Term Care, Hospital,
                      Home Health) once their first shift is verified! Tap to start referring or to
                      track existing referrals.
                    </Text>
                  </Stack>
                </CardContent>
                <ChevronRightIcon />
              </Stack>
            </CardActionArea>
          </Card>
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
