import { LoadingButton, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

interface LogoutConfirmationDialogProps {
  modalState: UseModalState;
  onLogout: () => Promise<void>;
  isLoading: boolean;
}

function LogoutConfirmationDialog(props: LogoutConfirmationDialogProps) {
  const { modalState, onLogout, isLoading } = props;

  return (
    <Dialog fullWidth open={modalState.modalIsOpen} maxWidth="sm" onClose={modalState.closeModal}>
      <DialogContent>
        <DialogContentText align="left" fontSize={18}>
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          variant="text"
          onClick={() => {
            modalState.closeModal();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          variant="text"
          onClick={async () => {
            await onLogout();
            modalState.closeModal();
          }}
        >
          Log out
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutConfirmationDialog;
